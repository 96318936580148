<template>
  <div class="mobileNavContainer flex-col items-center">
    <div class="head flex-row justify-between items-center">
      <img
        class="logo"
        :src="logo"
        @click="goHome"
        alt=""
      />
      <div class="flex-1 flex-row items-center">
        <span class="right_item ivu-mr-8">
          <Input
            size="small"
            icon="ios-search"
            placeholder="搜索内容"
            style="width: auto"
            v-model="searchVal"
            @on-click="jumpSearch"
            :style="{opacity: 0}"
          />
        </span>
       
        <span class="right_item" ref="spanBox">
          <span class="ivu-mr-8" :class="$i18n.locale === 'zh-CN' ? 'blueC' : ''" @click="checkLang(0)">中文</span>
          <span :class="$i18n.locale === 'en-US' ? 'blueC' : ''" @click="checkLang(1)">EN</span>
        </span>
      </div>
      <div class="flex-row items-center">
        <Icon type="md-menu" @click="showList = !showList" />
      </div>
    </div>

    <transition>
      <div class="menu" v-if="showList">
        <Menu  accordion>
          <MenuItem
           @click="$router.push('/keymos'),showList=false"
            >{{ $zhEn(keymosName,keymosNameEn) }}</MenuItem
          >
          <Submenu
            :name="'0-' + index"
            v-for="(items, index) in contents"
            :key="index"
          >
            <template #title>
              <span>{{ $zhEn(items.contentTitle,items.contentTitleEn) }}</span>
            </template>
            <div v-if="!items.more">
              <MenuItem v-for="(itm, idx) in items.menuNames" :key="idx" @click="jumpFn(items,itm,idx)">{{
                $zhEn(itm.title,itm.title_en)
              }}</MenuItem>
            </div>

            <Submenu
              v-else
              :name="'0-' + index + '-' + i"
              v-for="(item, i) in items.content"
              :key="i"
            >
              <template #title>
                {{ $zhEn(item.title,item.title_en) }}
              </template>
              <MenuItem
                :name="'0-' + index + '-' + i + '-' + ix"
                v-for="(el, ix) in item.product"
                :key="ix"
                @click="productJumpFn(el)" 
              >
                {{ el.name }}
              </MenuItem>
            </Submenu>
          </Submenu>
        </Menu>
      </div>
    </transition>
  </div>
</template>

<script>
import { Menu, Submenu, MenuItem, Icon, Input } from "view-ui-plus";

import { useUserStore } from "@/stores/user";
import { headerNavs } from "@/api/home";

export default {
  name: "Navbar01-Mobile",
  components: { Menu, Submenu, MenuItem, Icon, Input },
  props: {
    logo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showList: false,
      contents: [
        {
          contentTitle: "产品中心",
          more: true,
          content: [
            {
              subMenu: "嵌入式存储",
              items: [
                {
                  name: "LPDDR",
                  route: {
                    path: "/product/03",
                  },
                },
                {
                  name: "UFS",
                  route: {
                    path: "/product/04",
                  },
                },
                {
                  name: "eMMC",
                  route: {
                    path: "/product/05",
                  },
                },
                {
                  name: "uMCP",
                  route: {
                    path: "/product/05",
                  },
                },
                {
                  name: "eMCP",
                  route: {
                    path: "/product/05",
                  },
                },
              ],
            },
            {
              subMenu: "内存产品",
              items: [
                {
                  name: "DDR5 UDIMM",
                  route: {
                    path: "/product/06",
                  },
                },
                {
                  name: "DDR5 SODIMM",
                  route: {
                    path: "/product/02",
                  },
                },
              ],
            },
          ],
        },
        {
          contentTitle: "解决方案",
          more: false,
          menuNames: [
            
          ],
        },
        {
          contentTitle: "关于我们",
          more: false,
          menuNames: ["企业介绍", "发展历程", "员工风采", "荣誉资质"],
        },
        {
          contentTitle: "联系我们",
          more: false,
          menuNames: ["业务咨询", "人才招聘"],
        },
      ],
      searchVal: '',
      keymosName: '',
      keymosNameEn: '',
      productNews: [
        { name: 'All', name_en: 'Enter products',  products: true },
        { name: 'All', name_en: 'Enter products',  products: true },
      ]
    };
  },
  mounted(){
    this.getNavs()
  },
  methods: {
    productJumpFn(el){
      if (el.products) {
        this.$router.push('/products?id=' + el.id)
      } else {
        this.$router.push({ path: '/detail1', query: { id: el.id } })
      }
      this.showList = false
    },
    jumpFn(item,itm,itmIdx){
      console.log(item)
      if (item.id === 3) {
        this.$router.push('/solutiondetail?id=' + itm.id)
      } else if (item.id === 4) {
        window.location.href = itm.jump_url
      } else if (item.id === 7) {
        this.$router.push('/newslist?id=' + itm.id )
      } else if (item.id === 5) {
        this.$router.push(`/contactus?type=${itm.id == 1 ? 0 : 1}` )
      } else if (item.id === 6) {
        this.$router.push(`/serviceSupport?type=${itmIdx + 1}` )
      }
      this.showList = false
    },  
    async getNavs(){
      let res = await headerNavs()
      if (res.code === 200001) {
        console.log(res.data);
        localStorage.setItem("menus", JSON.stringify(res.data));
        res.data.forEach((item,idx)=>{
            item.contentTitle = item.title
            item.more = false
            item.contentTitleEn = item.title_en
            if (item.id === 1) {
              this.keymosName = item.title
              this.keymosNameEn = item.title_en
            } else {
              
              if(item.id == 3) {
                item.menuNames = item.data.list
              } else if(item.id == 2) {
                item.more = true
                item.content = item.data
              } else {
                item.menuNames = item.data
              }
              
            } 
        })
        this.contents = res.data
        console.log(this.contents.splice(0,1))
      }
    },
    goRoute(route) {
      this.$router.push(route);
    },
    goHome() {
      this.$router.push({
        path: "/",
      });
      this.showList = false
    },
    checkLang(idx) {
      this.$refs.spanBox.children[0].style.color = "#999999";
      this.$refs.spanBox.children[1].style.color = "#999999";
      this.$refs.spanBox.children[idx].style.color = "#0083ff";
      this.changelang(idx);
    },
    changelang(t) {
      if (this.$i18n.locale == "zh-CN" && t == 1) {
        this.$i18n.locale = "en-US";
        useUserStore().setLanguage("en-US");
      } else if (this.$i18n.locale == "en-US" && t == 0) {
        this.$i18n.locale = "zh-CN";
        useUserStore().setLanguage("zh-CN");
      }
    },
    jumpSearch() {
      if (this.searchVal) {
        this.$router.push({ path: "/search", query: { keyword: this.searchVal } });
        this.showList = false
      }
    },
  },
};
</script>

<style scoped lang="less">
.mobileNavContainer {
  width: 100%;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 101;
  box-shadow: 0px 3px 20px 0px rgba(96, 96, 96, 0.1);

  .head {
    height: @--nav-height-mobile;
    width: 100%;
    background-color: #fff;
    padding-left: 20px;
    padding-right: 20px;

    .logo {
      width: 130px;
      cursor: pointer;
    }

    .right_item {
      // padding: 0px 20px;
      color: #999999;
      border-left: 1px rgba(255, 255, 255, 0.5) solid;

      // &:first-of-type {
      //   border-left: none; // 去掉第一个类型为 .right_item 的元素的边框样式
      // }

      img {
        width: 20px;
        height: 20px;
        vertical-align: top;
        margin-right: 10px;
      }

      span {
        cursor: pointer;
      }

      span:hover {
        color: #0083ff;
      }
    }

    .right_item:last-child {
      padding-right: 0;
      /* 调整为你需要的内边距值 */
    }

    .btn {
      width: 80px;
      height: 30px;
      background: linear-gradient(180deg, #00b4ed 0%, #3e92f4 100%);
      border-radius: 16px;
      border: 1px solid rgba(255, 255, 255, 0.33);
      font-size: 14px;
      margin-right: 20px;
      color: #fff;
      cursor: pointer;
    }
  }

  .menu {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    overflow-y: auto;

    .wait {
      height: 12px;
      width: 29px;
      margin-left: 4px;
    }

    :deep {
      .ivu-menu {
        width: 100% !important;

        .ivu-menu-item,
        .ivu-menu-submenu-title {
          height: @--nav-height-mobile;
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
        }

        .ivu-menu-submenu-has-parent-submenu {
          background-color: #f9faf9;
        }

        // .ivu-menu:hover,
        .ivu-menu-submenu-title:hover,
        .ivu-menu-item:hover {
          background-color: #ecf7fc;
        }
      }
    }
  }
}

::v-deep .ivu-icon-md-menu {
  font-size: 40px;
  // font-weight: 600;
  color: #289ff2;
  cursor: pointer;
}

.blueC {
  color: #0083ff;
}
</style>
