import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Recruit from '@/components/contactUs/Recruit.vue'
import Consult from '@/components/contactUs/Consult.vue'
import constValue from '@/common/constValue'
import { getTDK } from '@/api/home'
import { reqConfig } from "@/api/contact";
import { useUserStore } from "@/stores/user";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/newslist',
      name: 'news',
      component: () => import("@/views/NewsList.vue")
    },
    {
      path: '/newsdetail',
      component: () => import("@/views/NewsDetail.vue")
    },
    {
      path: '/keymos',
      name: "keymos",
      meta: {
        title: 'KEYMOS'
      },
      component: () => import("@/views/Keymos.vue")
    },
    {
      path: '/products',
      name: "product",
      meta: {
        title: '产品中心',
        title_en: 'Products'
      },
      component: () => import("@/views/ProductCenter.vue")
    },
    // {
    //   path: '/detail',
    //   component: () => import("@/views/ProductDetail.vue")
    // },
    {
      path: '/detail1',
      component: () => import("@/views/ProductDetail1.vue")
    },
    {
      path: '/solution',
      name: "solution",
      meta: {
        title: '应用终端',
        title_en: 'Major Applications'
      },
      component: () => import("@/views/Solution.vue")
    },
    {
      path: '/solutiondetail',
      component: () => import("@/views/SolutionDetail.vue"),
      meta: {  
        title: '应用终端详情',  
        keywords: '动画效果,小工具,小游戏,合集',  
        description: '包含动画效果(特效)，研究向的小工具，以及自己做的小游戏的项目合集'  
      } ,
    },
    {
      path: '/aboutus',
      name: "about_us",
      meta: {
        title: '关于我们',
        title_en: 'About us'
      },
      component: () => import("@/views/AboutUs.vue")
    },
    {
      path: '/contactus',
      name: 'contactus',
      meta: {
        title: '联系我们',
        title_en: 'Contact us'
      },
      component: () => import("@/views/ContactUs.vue"),
      // children: [
      //   { path: 'recruit', component: Recruit },
      //   { path: 'consult', component: Consult },
      // ]
    },
    {
      path: '/recruitdetail',
      component: () => import("@/views/RecruitDetail.vue")
    },
    {
      path: '/search',
      component: () => import("@/views/SearchView.vue")
    },
    {
      path: '/serviceSupport',
      name: 'service_and_support',
      meta: {
        title: '服务与支持',
        title_en: 'Service and Support'
      },
      component: () => import("@/views/ServiceSupport.vue")
    },
    {
        path: '/:pathMatch(.*)',
        name: '404',
        meta: {
            title: '404'
        },
        component: () => import("@/views/error/404/index.vue")
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        if (to.path != from.path) {
          //如果不是同一个页面 先回到顶部
          window.scrollTo({
            top: 0,
          });
        }
        //如果是同一个页面立即往下滑 
        //如果不是同一个页面则稍微延时后再往下滑
        setTimeout(() => {
          resolve({
            el: to.hash,
            top: constValue.scrollOffset,
            behavior: 'smooth',
          })
        }, to.path == from.path ? 0 : 300)
      })
    } else {
      return {
        top: 0,
      }
    }
  },
})
// const historyRoutes = []
router.beforeEach( async (to, from, next)=> {
  console.log(to.name)
  if (to.path === '/contactus') {
    
  }
  useUserStore().getSysConfig()
  useUserStore().getMenuList()
  let res = await getTDK()
  if(res.code == 200001) {
    
    res.data.list.map(el => {
      
      if(el.location == "home" && to.name == 'home') {
        

        window.SeoUpdate(useUserStore().language == 'zh-CN' ? el.page_title : el.page_title_en, useUserStore().language == 'zh-CN' ? el.page_keywords : el.page_keywords_en,useUserStore().language == 'zh-CN' ? el.page_description : el.page_description_en);
      } else if(el.location == "contact_us" && to.name == 'contactus') {
        window.SeoUpdate(useUserStore().language == 'zh-CN' ? el.page_title : el.page_title_en, useUserStore().language == 'zh-CN' ? el.page_keywords : el.page_keywords_en,useUserStore().language == 'zh-CN' ? el.page_description : el.page_description_en);
      } else if(el.location == "service_and_support" && to.name == 'service_and_support') {
        window.SeoUpdate(useUserStore().language == 'zh-CN' ? el.page_title : el.page_title_en, useUserStore().language == 'zh-CN' ? el.page_keywords : el.page_keywords_en,useUserStore().language == 'zh-CN' ? el.page_description : el.page_description_en);
      } else if(el.location == "keymos" && to.name == 'keymos') {
        window.SeoUpdate(useUserStore().language == 'zh-CN' ? el.page_title : el.page_title_en, useUserStore().language == 'zh-CN' ? el.page_keywords : el.page_keywords_en,useUserStore().language == 'zh-CN' ? el.page_description : el.page_description_en);
      } else if(el.location == "solution" && to.name == 'solution') {
        window.SeoUpdate(useUserStore().language == 'zh-CN' ? el.page_title : el.page_title_en, useUserStore().language == 'zh-CN' ? el.page_keywords : el.page_keywords_en,useUserStore().language == 'zh-CN' ? el.page_description : el.page_description_en);
      } else if(el.location == "product" && to.name == 'product') {
        window.SeoUpdate(useUserStore().language == 'zh-CN' ? el.page_title : el.page_title_en, useUserStore().language == 'zh-CN' ? el.page_keywords : el.page_keywords_en,useUserStore().language == 'zh-CN' ? el.page_description : el.page_description_en);
      } else if(el.location == "about_us" && to.name == 'about_us') {
        window.SeoUpdate(useUserStore().language == 'zh-CN' ? el.page_title : el.page_title_en, useUserStore().language == 'zh-CN' ? el.page_keywords : el.page_keywords_en,useUserStore().language == 'zh-CN' ? el.page_description : el.page_description_en);
      } else if(el.location == "news" && to.name == 'news') {
        window.SeoUpdate(useUserStore().language == 'zh-CN' ? el.page_title : el.page_title_en, useUserStore().language == 'zh-CN' ? el.page_keywords : el.page_keywords_en,useUserStore().language == 'zh-CN' ? el.page_description : el.page_description_en);
      }
    })
  }


  next()
})

// router.afterEach((to, from) => {
//   if (to.path === '/contactus') {
//     // router.go(0);
//   }
// });


const writer = (to) => {
  //首先找到head里的meta
  const deleArr = [];
  document.head.childNodes.forEach(item => {
    switch (item.tagName) {
      case "META":
        deleArr.push(item);
        break;
      case "TITLE":
        //顺便设置title
        document.title = to.meta.title || to.name;
        break;
    }
  });
 
  //删除原来的meta
  deleArr.forEach(item => {
    document.head.removeChild(item);
  })
 
  //添加想要的meta（全局）
  const metas = document.createElement("META");
  const creatArr = [
    { charset: "utf-8" },
    { "http-equiv": "X-UA-Compatible", content: "IE=edge" },
    //视图缩放
    { name: "viewport", content: "width=device-width,initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no,minimal-ui" }
  ];
 
  //将单个路由设定的meta加到creatArr数组中
  //局部meta和全局meta重复时，局部meta替换全局meta
  const tmpArr = to.meta.data ? to.meta.data.concat() : [];
 
  if (tmpArr.length > 0) {
    to.meta.data.forEach((item, index) => {
      creatArr.forEach((ele, ind) => {
        if (Object.keys(item)[0] == Object.keys(ele)[0]) {
          creatArr[ind] = JSON.parse(JSON.stringify(item));
          tmpArr.splice(index, 1);
        }
      });
    });
  }
 
  //生成合并后的数组
  const eleArr = creatArr.concat(tmpArr);
 
  //将设定的值写入文档片段
  const creatFrag = document.createDocumentFragment();
  eleArr.forEach(ele => {
    creatFrag.append(metas.cloneNode());
    Object.entries(ele).forEach(item => {
      creatFrag.lastChild.setAttribute(item[0], item[1]);
    });
  });
 
  //将文档片段写入head
  document.head.prepend(creatFrag);
}


export default router
