import axios from "axios";
import { Message } from "view-ui-plus";
import { getToken } from "./tokenUtil";

// 创建一个 axios 实例
const service = axios.create({
  baseURL: import.meta.env.VITE_BASE_API,
  timeout: 50000,
});

// 请求拦截器
// service.interceptors.request.use(
//   (config) => {
//     const AUTH_TOKEN = getToken();
//     if (!config.headers["Authorization"] && AUTH_TOKEN) {
//       config.headers["Authorization"] = `Bearer ${AUTH_TOKEN}`;
//     }
//     return config;
//   },
//   (error) => {
//     // 发送失败
//     console.log(error);
//     Promise.reject(error);
//   }
// );

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data;

    // 这个状态码是和后端约定的
    const { code, msg } = dataAxios;
    if (code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口
      return dataAxios;
    } else {
      if (code == 200001) {
        return dataAxios;
      } else if ((code == 10000 || code == 422) && msg) {
        Message.error({
          content: msg,
          duration: 3,
        });
        return Promise.reject(dataAxios);
      } else {
        return Promise.reject(dataAxios);
      }
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
