<template>
  <div v-if="sysConfig">
    <Header v-if="!isMobile" :navs="list" :logo="sysConfig.log_top_image"></Header>
    <MobileNav v-else :logo="sysConfig.log_top_image"></MobileNav>
  </div>
  
</template>

<script setup>
import Header from '@/common/pageComponents/pcNav.vue';
import MobileNav from '@/common/pageComponents/01MobileNavbar.vue'
import { useUserStore } from "@/stores/user";
import { computed, ref } from 'vue';
const store = useUserStore()
let isMobile = computed(()=>store.isMobile)
let sysConfig = computed(()=>store.sysConfig)
let list = computed(()=>store.menusList)
onMounted(()=>{
})

</script>