<template>
  <div class="message_container flex-col">
    <div class="img_box">
      <Image :src="props.img" fit="cover" :alt="props.title" />
    </div>

    <div class="text_box flex-1">
      <p class="title flex-row items-center justify-between">
        <span>{{ props.title }}</span>
        <span>{{ props.created_at }}</span>
      </p>
      <p class="desc">{{ props.desc }}</p>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  img: {
    type: String,
  },
  title: {
    type: String,
  },
  desc: {
    type: String,
  },
  created_at: {
    type: String,
  }
});
</script>

<style lang="less" scoped>
.message_container {
  width: 270px;
  // height: 336px;
  cursor: pointer;

  &:hover .img_box{
    ::v-deep .ivu-image{
        transform: scale(1.2);
    }
  }

  .img_box {
    width: 100%;
    height: 202px;
    overflow: hidden;
    border-radius: 4px;
    ::v-deep .ivu-image{
      width: 100%;
      height: 202px;
      border-radius: 4px;
      transition: transform 0.3s ease;
    }
    
  }

  .text_box {
    padding-top: 20px;
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
    }
    .desc {
      margin-top: 8px;
      font-size: 18px;
      font-weight: 500;
      color: #222222;
      line-height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    }
  }
}
</style>
