<template>
  <div class="newmessage_container flex-center">
    <div class="main">
      <div class="page_title">{{ useUserStore().language == 'zh-CN' ? props.moduleTitle : props.moduleTitleEn }}</div>
      <div class="messages flex-row flex-wrap justify-center">
        <Message
          v-for="(item, index) in props.datas"
          :key="index"
          :img="item.img"
          :desc="useUserStore().language == 'zh-CN' ? item.desc : item.desc_en"
          :title="useUserStore().language == 'zh-CN' ? item.title : item.title_en"
          :created_at="item.created_at"
          @click="jumpMDet(item.id,item)"
        ></Message>
      </div>
      <div class="more_btn flex-row items-center" @click="jumpMessages">
        <span>{{ $t('learnMore') }}</span>
        <img
          src="@/assets/images/arrow right@2x.png"
          width="16"
          height="16"
          :alt="$t('learnMore')"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Message from "@/common/baseComponents/Message.vue";
import { useRouter } from "vue-router";
import { defineProps } from "vue";

import { useUserStore } from "@/stores/user";

const props = defineProps({
  moduleTitle: {
    type: String
  },
  moduleTitleEn: {
    type: String
  },
  datas: {
    type: Array
  }
})


const router = useRouter()

const jumpMessages = () => {
  let data = JSON.parse(localStorage.getItem("menus"))
    .find((item) => item.id === 7)
  router.push(`/newslist?id=${data.data[0].id}`)
}

const jumpMDet = (id,item) => {
  localStorage.setItem("currentCategory",JSON.stringify(item.article_category))
  router.push(`/newsdetail?id=${item.id}`)
  
}
</script>

<style lang="less" scoped>
.newmessage_container {
  // min-height: 750px;

  .main {
    width: @--effect-width;
    height: 100%;
    padding: 100px 0px;

    .messages{
      row-gap: 12px;
      column-gap: 30px;
    }
    .page_title {
      height: 50px;
      font-size: 36px;
      font-weight: 600;
      color: #222222;
      line-height: 50px;
      text-align: center;
      margin-bottom: 60px;
    }

    .more_btn {
      width: 120px;
      height: 40px;
      background: linear-gradient(90deg, #0083ff 0%, #0ec7d9 100%);
      border-radius: 4px;
      padding: 10px 24px;
      margin: 0 auto;
      margin-top: 60px;
      cursor: pointer;
      span {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #ffffff;
        white-space: nowrap;
      }
      img {
        transition: transform .5s ease;
      }

      &:hover {
        img {
          transform: translateX(8px);
        }

        background: linear-gradient(90deg, #0066ff 0%, #00b2ff 100%);
      }
    }
  }
}
</style>
