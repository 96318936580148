<template>
  <div
    class="footer_contaitner"
  >
    <div
      class="main flex-row flex-wrap justify-between"
      v-if="!useUserStore().isMobile"
    >
      <div class="flex-1">
        
        <div class="navigation_box flex-row flex-wrap justify-between flex-1">
          <div class="line"></div>
          <div class="nav_item" v-for="(item, index) in useUserStore().language == 'zh-CN' ? data : filteredArray" :key="index">
            <div class="title">{{ $zhEn(item.title, item.title_en) }}</div>
            <div :class="item.id === 3 ? 'options1' : 'options'">
              <p
                v-for="(itm, idx) in item.data"
                :key="idx"
                @click="jumpFn(item, itm,idx)"
              >
                {{ $zhEn(itm.title, itm.title_en) }}
              </p>
            </div>
          </div>
        </div>
        <div class="address_info flex-row justify-between">
          <div class="address flex-row">
            <p class="p1">{{ $t("address") + "：" }}</p>
            <p class="p2 flex-wrap">
              {{ $zhEn(footetConfig.address, footetConfig.addressEn) }}
            </p>
          </div>
          <div class="channel flex-row" v-if="channelList.length != 0">
            <p class="p1">{{ $t("channels") + "：" }}</p>
            <div class="channel-right">
              <div class="flex-row flex-wrap">
                <template v-for="(item,index) in channelList" :key="index">
                  <a :href="item.jump_url" target="_blank">{{ $zhEn(item.title, item.title_en) }}</a>
                </template>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div class="logo_box flex-col">
        <div class="qcode-box flex-row ai-center">
          <div class="qcode-item row column">
            <img :src="footetConfig.website_image_1" :alt="$zhEn(footetConfig.website_image_1_title, footetConfig.website_image_1_title_en)" />
            <div>{{ $zhEn(footetConfig.website_image_1_title, footetConfig.website_image_1_title_en) }}</div>
          </div>
          <div class="qcode-item row column">
            <img :src="footetConfig.website_image_2" :alt="$zhEn(footetConfig.website_image_2_title, footetConfig.website_image_2_title_en)" />
            <div>{{ $zhEn(footetConfig.website_image_2_title, footetConfig.website_image_2_title_en) }}</div>
          </div>
        </div>
        <div class="phone_btn">
          <img src="@/assets/images/phone_icon@2x .png" :alt="footetConfig.phone" />
          <span>{{ footetConfig.phone }}</span>
        </div>
        <div class="media_box flex-row justify-between">
          <a v-for="(item) in meadialList" :key="item" :href="item.jump_url" target="_blank">
            <img :src="item.image" :alt="item.image"  />
          </a>
        </div>
      </div>
      
      
      
      
      <div class="bottom_text">
        {{ $t('bottom_text2') }} <span> | <a href="https://beian.miit.gov.cn" target="_blank" style="color: #222222;">粤ICP备2024272156号-1</a></span>
      </div>
      <!-- <div class="bottom_text2">
        <a :href="mapsite" target="_blank">{{ $t('website_map') }}</a>
      </div> -->
    </div>
    <div class="mobile_main flex-col items-center" v-else>
      <img
        class="code_img"
        src="@/assets/images/mobile_code_pic@2x.jpg"
        :alt="$t('bottom_text1')"
      />
      <div class="mobile_text">{{ $t('bottom_text1') }}</div>
      <div class="phone_btn flex-center">
        <img src="@/assets/images/phone16_icon@2x.png" :alt="footetConfig.phone" />
        <span>{{ footetConfig.phone }}</span>
      </div>
      <div class="bottom_text">
        {{ $t('bottom_text2') }} <span> | 粤ICP备2024272156号-1</span>
      </div>
      
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import footerBg from "@/assets/images/footer_bg.png";

import { useUserStore } from "@/stores/user";
import { computed } from "vue";
import { reqConfig } from "@/api/contact";
import { reactive, onMounted } from "vue";
import { ref } from "vue";
import { headerNavs, getChannels, getMeadia } from "@/api/home";
import router from "@/router";
let base = import.meta.env.VITE_BASE_API;
console.log(base);
const store = useUserStore();

let isMobile = computed(() => store.isMobile);

let isEn = computed(() => store.language);

let mapsite = ref(`${base}/sitemap.xml`)
const route = useRoute();
let filteredArray = ref([])
let data = ref([
  // { name: "KEYMOS", options: ["关于KEYMOS", "品牌优势", "产品介绍"] },
  // { name: "产品中心", options: ["嵌入式存储", "内存产品"] },
  // {
  //   name: "解决方案",
  //   options: [
  //     "智能手机",
  //     "平板电脑",
  //     "PC服务器",
  //     "车载电子",
  //     "智能电视",
  //     "教育电子",
  //     "智能穿戴",
  //     "智能家居",
  //     "智慧医疗",
  //     "工业4.0",
  //     "无人机",
  //   ],
  // },
  // {
  //   name: "关于我们",
  //   options: ["公司介绍", "发展历程", "员工风采", "荣誉资质"],
  // },
  // { name: "联系我们", options: ["业务咨询", "人才招聘"] },
]);

let footetConfig = reactive({
  logo: "",
  phone: "",
  address: "",
  addressEn: "",
  website_image_1: "",
  website_image_1_title: "",
  website_image_1_title_en: "",
  website_image_2: "",
  website_image_2_title: "",
  website_image_2_title_en: "",
});

const channelList = ref([])
const meadialList = ref([])


const getConfig = async () => {
  let res = await reqConfig();
  if (res.code === 200001) {
    let { 
      log_bottom_image, 
      telephone, 
      address, 
      address_en,
      website_image_1,
      website_image_1_title,
      website_image_1_title_en,
      website_image_2,
      website_image_2_title,
      website_image_2_title_en,
      site_inclusion_config
    } = res.data;
    footetConfig.address = address;
    footetConfig.addressEn = address_en;
    footetConfig.logo = log_bottom_image;
    footetConfig.phone = telephone;
    footetConfig.website_image_1 = website_image_1;
    footetConfig.website_image_1_title = website_image_1_title;
    footetConfig.website_image_1_title_en = website_image_1_title_en;
    footetConfig.website_image_2 = website_image_2;
    footetConfig.website_image_2_title = website_image_2_title;
    footetConfig.website_image_2_title_en = website_image_2_title_en;

    
  
  }
};

const getNavs = async () => {
  
  let res = await headerNavs();
  if (res.code === 200001) {
    localStorage.setItem("menus", JSON.stringify(res.data));
    getMenus();
    getChannel();
    media()
  }
};

const getMenus = () => {
  let menus = JSON.parse(localStorage.getItem("menus"));
  console.log(menus);
  filteredArray.value = menus.filter(item => item.id !== 7 && item.id !== 6);
  data.value = menus;
  console.log(filteredArray.value)
  data.value.forEach((item) => {
    if (item.id === 1) {
      item.data = [
        { title: "关于KEYMOS", title_en: "About KEYMOS", hash: "#aboutKeymos" },
        { title: "品牌优势", title_en: "Brand advantage", hash: "#brand" },
        {
          title: "产品类型",
          title_en: "Product introduction",
          hash: "#introduction",
        },
      ];
    } else if (item.id === 3) {
      item.data = item.data.list;
    }
  });
};

const media = async() => {
  let res = await getMeadia();
  meadialList.value = res.data.list
}

const getChannel = async() => {
  let res = await getChannels();
  channelList.value = res.data.list;
}

const jumpFn = (item, itm,index) => {
  console.log(item);
  console.log(itm);
  switch (item.id) {
    case 1:
      router.push(`/keymos${itm.hash}`);
      break;
    case 2:
      router.push(`/products?id=${itm.id}`);
      break;
    case 3:
      router.push({
        path: '/solutiondetail',
        query:{
          id: itm.id
        }
      })
      break;
    case 4:
      console.log(itm);
      window.location.href = itm.jump_url;
      break;
    case 5:
      router.push(`/contactus?type=${itm.title == '业务咨询' ? 0 : itm.title == '人才招聘' ? 1 : 2}`);
      break;
    case 6:
      router.push(`/serviceSupport?type=${index + 1}`);
      break;
    case 7:
      router.push(`/newslist?id=${itm.id}`);
      break;
    
  }
};

onMounted(async () => {
  await getNavs();
  getConfig();
  
});
</script>

<style lang="less" scoped>
.footer_contaitner {
  width: 100%;
  // min-height: 494px;
  background: url("@/assets/images/footer_bg@2x.png") 50% 50% / cover;
  .main {
    @media (max-width: 768px) {
      width: 90%;
    }

    width: @--effect-width;
    margin: 0 auto;
    height: 100%;
    padding: 90px 0px 40px 0px;
    position: relative;

    .logo_box {
      width: 240px;
      // height: 344px;
      margin-left: 60px;
      .qcode-item{
        width: 100px;
        &:last-child{
          margin-left: 20px;
        }
        img{
          width: 100px;
          width: 100px;
        }
        div{
          text-align: center;
          color: #222222;
          padding-top: 10px;
          font-size: 12px;
        }
      }

      .logo_img {
        width: 184px;
        height: 80px;
      }

      .phone_btn {
        margin-top: 40px;
        height: 48px;
        background: linear-gradient(90deg, #0083ff 0%, #0ec7d9 100%);
        border-radius: 10px;
        padding: 12px 32px;

        img {
          width: 24px;
          height: 24px;
        }

        span {
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
          vertical-align: top;
          margin-left: 12px;
        }
      }

      .media_box{
        padding-top: 60px;
        img{
          width: 28px;
          height: 28px;
        }
      }

      
    }

    .navigation_box {
      // width: 800px;
      position: relative;
      // column-gap: 70px;

      @media (max-width: 769px) {
        width: 100%;
        justify-content: left;
      }

      .line {
        position: absolute;
        top: 56px;
        width: 100%;
        height: 1px;
        background: #E8E8E8;
      }
      .nav_item {
        margin-right: 12px;
        .title {
          height: 56px;
          font-size: 16px;
          line-height: 56px;
          font-weight: 500;
          color: #222222;
        }

        .options1 {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          writing-mode: vertical-lr;
          padding-top: 30px;
          row-gap: 24px;
          column-gap: 12px;
          // width: 56px;
          white-space: nowrap;
          height: 210px;
          writing-mode: vertical-lr;

          p {
            writing-mode: horizontal-tb;
            font-size: 12px;
            font-weight: 400;
            color: #222222;
            height: 20px;
            line-height: 20px;
            cursor: pointer;

            &:hover {
              color: #0083ff;
            }
          }
        }

        .options {
          display: flex;
          // flex-direction: row;
          flex-direction: column;
          flex-wrap: wrap;
          // writing-mode: vertical-lr;
          padding-top: 30px;
          row-gap: 12px;
          column-gap: 12px;
          // width: 56px;
          white-space: nowrap;
          height: 210px;

          @media (max-width: 768px) {
            row-gap: 6px;
          }

          p {
            // writing-mode: horizontal-tb;
            font-size: 12px;
            font-weight: 400;
            color: #222222;
            height: 20px;
            line-height: 20px;
            cursor: pointer;

            &:hover {
              color: #0083ff;
            }
          }
        }
      }

    }
    .address_info{
      padding-top: 60px;
      .address {
      
        white-space: wrap;
        font-size: 14px;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
        
        .p1 {
          white-space: nowrap;
          width: 62px;
        }
        .p2{
          max-width: 400px;
        }
      }

      .channel{
        color: #222222;
        line-height: 24px;
        .channel-right{
          margin-right: 12px;
          div{
            width: 260px;
          }
          a{
            font-size: 14px;
            color: #0083FF;
            margin-right: 12px;
            margin-bottom: 10px;
            min-width: 100px;
          }
          .line{
            margin: 0 12px;
            color: #0083FF;
          }
          
        }
      }
    }

    

    .bottom_text {
      margin-top: 68px;
      width: 100%;
      min-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
      text-align: center;
      bottom: 0;
    }
    .bottom_text2{
      text-align: center;
      width: 100%;
      color: #222222;
      line-height: 20px;
      margin-top: 5px;
    }
  }

  .mobile_main {
    width: 90%;
    margin: 0 auto;
    padding: 90px 0px 40px 0px;

    .code_img {
      width: 105px;
      height: 105px;
      object-fit: cover;
    }

    .mobile_text {
      height: 20px;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 20px;
      margin-top: 12px;
    }

    .phone_btn {
      width: 150px;
      height: 30px;
      background: linear-gradient(90deg, #0083ff 0%, #0ec7d9 100%);
      border-radius: 8px;
      font-weight: 500;
      font-size: 12px;
      color: #ffffff;
      line-height: 18px;
      margin-top: 16px;
      margin-bottom: 35px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }

    .bottom_text {
      margin-top: 68px;
      width: 100%;
      min-height: 20px;
      font-size: 14px;
      font-weight: 400;
      
      line-height: 20px;
      text-align: center;
      bottom: 0;
    }
  }
}
</style>
