import request from "@/utils/apiRequest"

export const reqSubmit = (data) => request({ method: 'post', url: '/api/contact_us', data })

export const reqAddress = () => request({ method: 'get', url: '/api/company_address' })

export const reqConfig = () => request({ method: 'get', url: '/api/site_config' })



export const reqRecruitmentList = (pn) => request({ method: 'get', url: '/api/recruitment?pn=' + pn })

export const reqRecruitmentHr = () => request({ method: 'get', url: '/api/recruitment_contacts' })

export const reqRecruitmentDeltail = (id) => request({ method: 'get', url: '/api/recruitment/' + id })

export const reqSendFile = (data) => request({ method: 'post', url: '/api/recruitment_resume_submissions', data })


