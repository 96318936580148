<template>
  <div class="goodpro_container">
    <div class="main">
      <div class="page_title">{{ $t('selectedProducts') }}</div>
      <div class="flex-row flex-wrap products justify-center">
        <Product v-for="(item, idx) in proData" :key="idx" :id="item.id" :desc="item.desc" :desc_en="item.desc_en" :title="item.title" :img="item.img"
          :bgImg="item.bgImg" @click.stop="jumpFn(item.jump_url)"></Product>
      </div>
    </div>
  </div>
</template>

<script setup>
import Product from '@/common/baseComponents/Product.vue';
import { ref, onMounted } from 'vue';

import {
  homeGoodProducts
} from "@/api/home";

let proData = ref([
  { desc: '', title: '', img: '', bgImg: '' },
  { desc: '', title: '', img: '', bgImg: "" },
  { desc: '', title: '', img: '', bgImg: "" },
  { desc: '', title: '', img: '', bgImg: "" },
  { desc: '', title: '', img: '', bgImg: "" },
  { desc: '', title: '', img: '', bgImg: "" },
])

let getProData = async () => {
  let res = await homeGoodProducts()
  if (res.code === 200001) {
    console.log(res.data);
    proData.value = res.data.map((item, idx) => {
      return {
        desc: item.title,
        desc_en: item.title_en,
        title: item.name,
        id: item.id,
        jump_url: item.jump_url,
        img: item.image,
        bgImg:""
        }
    })
  }
}


const jumpFn = (url) => {
    window.location.href = url
} 

onMounted(() => {
  getProData()
})
</script>

<style lang="less" scoped>
.goodpro_container {
  background: #ffffff;

  .main {
    width: @--effect-width;
    height: 100%;
    padding: 100px 0px;
    margin: 0 auto;

    .page_title {
      height: 50px;
      font-size: 36px;
      font-weight: 600;
      color: #222222;
      line-height: 50px;
      text-align: center;
      margin-bottom: 60px;
    }

    .products {
      gap: 30px;
    }
  }
}
</style>
