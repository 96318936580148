import request from "@/utils/apiRequest"

export const homeBanners = ()=> request({ method: 'get', url: '/api/banner/1' })

export const homeGoodProducts = ()=> request({ method: 'get', url: '/api/home_products' })

export const homeApplay = ()=> request({ method: 'get', url: '/api/home_applications' })

export const messageList = ()=> request({ method: 'get', url: '/api/article' })

export const messageDeltail = (id)=> request({ method: 'get', url: `/api/article/${id}` })

export const articleRecommend = ()=> request({ method: 'get', url: '/api/article/recommend' })

export const homeRichText = () => request({ method: 'get', url: '/api/home_date' })

export const headerNavs = () => request({ method: 'get', url: '/api/navigation_bar' })

export const reqSearch = (k,pn) => request({ method: 'get', url: '/api/search?limit=6&keyword=' + k + '&pn=' + pn })

export const reqArticleCategoy = () => request({ method: 'get', url: '/api/article_categories' })

export const articleList = (params) => request({ method: 'get', url: '/api/article', params })

export const reqService = () => request({ method: 'get', url: '/api/service_and_supports' })

export const reqDownload = (params) => request({ method: 'get', url: '/api/download_centers', params })

export const getChannels = () => request({ method: 'get', url: '/api/purchasing_channels' })

export const getMeadia = () => request({ method: 'get', url: '/api/social_media' })

export const getTDK = () => request({ method: 'get', url: '/api/title_description_keywords_configs' })
