<!-- <template>
  <div class="space-container" :style="{
    '--row-gap': rowGap,
    '--column-gap': columnGap
  }">
    <slot></slot>
  </div>
</template> -->
  
<script>
import { h } from "vue";
export default {
  name: 'Space01',
  props: {
    rowGap: {
      type: String,
      default: '0'
    },
    columnGap: {
      type: String,
      default: '0'
    },
  },
  watch: {
    $slots() {
      console.log('watch$slots')
    }
  },
  components: {
  },
  computed: {
  },
  mounted() {
  },
  render() {
    //目前用的是css变量的方案
    //后面可以改成直接在style里面写margin
    const list = this.$slots.default();
    for (const item of list) {
      if (item.type == Symbol.for("v-fgt")) {
        if (item.children instanceof Array) {
          for (const child of item.children) {
            if (!child.props) {
              child.props = {}
            }
            if (!child.props['style']) {
              child.props['style'] = {}
            }
            child.props['style']['--row-gap'] = this.rowGap
            child.props['style']['--column-gap'] = this.columnGap
          }
        }

      } else {
        if (!item.props) {
          item.props = {}
        }
        if (!item.props['style']) {
          item.props['style'] = {}
        }
        item.props['style']['--row-gap'] = this.rowGap
        item.props['style']['--column-gap'] = this.columnGap
      }
    }
    return h("div", { class: 'space-container' }, list);
  },
  data() {
    return {
    }
  }
}
</script>
  
<style lang="less" scoped>
.space-container {
  :deep(> *) {
    margin-right: var(--column-gap);
    margin-bottom: var(--row-gap);

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}
</style>
  