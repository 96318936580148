import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import "./common/styles/index.less";
import router from './router'
import { pxToRem } from "@/utils/remUtil";
import { getRem } from "@/utils/amfeFlexible";
import zhEn from "@/utils/zhEn";
import { createMetaManager } from 'vue-meta';
import { flexible } from "@/utils/amfeFlexible";
import 'animate.css'

import { VueMasonryPlugin } from 'vue-masonry';

import i18n from './i18n';
import GimUIPlus from "gim-ui-plus"
import ViewUIPlus from "view-ui-plus"
import 'gim-ui-plus/lib/index.css'

import Space from "@/common/baseComponents/Space/index.vue";
import { Tabs } from 'ant-design-vue';
import 'element-plus/dist/index.css'
import '@/assets/css/el.css'

const app = createApp(App)

var _hmt = _hmt || [];
window._hmt = _hmt;
  (function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?792933958dc4014a08cf9428d562729a";
    var s = document.getElementsByTagName("script")[0]; 
    s.parentNode.insertBefore(hm, s);
  })();


window.SeoUpdate=function(SeoTitle, SeoKeywords, SeoDescription) {
  let _headDom = '',_title = '',_meta = ''; 

  _headDom = document.getElementsByTagName('head')[0]; //获取head节点
  _title = _headDom.getElementsByTagName("title")[0];  //获取head节点下的title节点
  _meta = _headDom.getElementsByTagName("meta"); //获取head节点下的meta节点，它一般是一个数组

  _title.innerText = SeoTitle;
  for (let index = 0; index < _meta.length; index++) { 
      switch (_meta[index].name) {
          case 'keywords':
              _meta[index].content = SeoKeywords;
              break;
          case 'description':
              _meta[index].content = SeoDescription;
              break;

          default:
              break;
      }
  } 
  console.log(_meta)
}

window.seometa = function (headerCode) {
  console.log(headerCode)
  let head = document.getElementsByTagName("head");
  let meta = '';

  for (let index = 0; index < headerCode.length; index++) { 
    meta = document.createElement("meta");
    meta.name = headerCode[index].name;
    meta.content = headerCode[index].content;
    head[0].appendChild(meta);
  }
  

}

window.ssrHeaderCode = function (headerCode) {
    // 1 编写正则
    const metaRegex = /<meta[^>]*>/g
    const scriptRegex = /<script[^>]*>[\s\S]*?<\/script>/g

    const metaMatches = headerCode.match(metaRegex)
    const scriptMatches = headerCode.match(scriptRegex)

    // 2 用map 映射出用数组包裹的 meta标签字符串和 script 标签的字符串
    const metaTags = metaMatches ? metaMatches.map(tag => tag.trim()) : []
    const scriptTags = scriptMatches ? scriptMatches.map(tag => tag.trim()) : []
    const scriptData = scriptMatches
    ? scriptMatches.map((tag) => {
      const scriptContent = tag.replace(/<\/?script[^>]*>/g, '').trim()
      const typeMatch = tag.match(/type="([^"]*)"/)
      const type = typeMatch ? typeMatch[1] : ''
      return { content: scriptContent, type }
    })
    : []
// 4 替换 meta 标签中 反斜线和 使用键值对的方式提取 mtea标签的内容
  const metaData = metaTags.map((item) => {
    const attributeRegex = /(\w+)\s*=\s*"([^"]*)"/g
    const attributes = []
    let match
    while ((match = attributeRegex.exec(item)) !== null) {
      const attributeName = match[1]
      const attributeValue = match[2]
      attributes.push(attributeName, attributeValue)
    }
    return attributes
  })


 let headerCodeMeta = ''
 if (metaData.length > 0) {
   headerCodeMeta = metaData.map((item) => {
     const obj = {}
     for (let i = 0; i < item.length; i += 2) {
       const key = item[i]
       const value = item[i + 1]
       obj[key] = value
     }
     return obj
   })
 } else {
   headerCodeMeta = []
 }
 return headerCodeMeta
}


app.use(i18n)
app.use(ViewUIPlus,{
    i18n
})
app.component('Space', Space)
app.use(GimUIPlus)
app.use(Tabs);
app.use(VueMasonryPlugin)
app.use(createPinia())
app.use(router)
app.config.productionTip = false;
app.config.globalProperties.$pxToRem = pxToRem;
app.config.globalProperties.$getRem = getRem;
app.config.globalProperties.$zhEn = zhEn;
app.config.globalProperties.$pxToRem = pxToRem;
flexible(window, document);
app.mount('#app')
