<script setup>
import SideNav from '@/common/pageComponents/SideNav.vue';

import { useUserStore } from "@/stores/user";
import { computed, ref } from 'vue';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import zhEn from 'element-plus/dist/locale/en.mjs';


import { useRoute } from "vue-router";
// import { useMeta } from 'vue-meta';
let route = useRoute();

const store = useUserStore()
let isMobile = computed(()=>store.isMobile)
let sideNavConfig = computed(() => store.sideNavConfig)

</script>

<template>
    
    <el-config-provider :locale="useUserStore().language == 'zh-CN' ? zhCn : zhEn">
      
      <router-view :key="route.fullPath" />
    </el-config-provider>
    <SideNav v-if="!isMobile && sideNavConfig" :jumpUrl="sideNavConfig.mapUrl" :qrCode="sideNavConfig.qrCode"></SideNav>
</template>

<style>

</style>
