<template>
  <div class="list-container" :style="{
    gridTemplateColumns: gridTemplateColumns
  }" :class="[`${type}`]">
    <div :class="type == 'big' ? 'flex-row' : 'flex-col'">
      <div v-for="(item, index) in list" :key="index" class="flex-col" :class="{item: type == 'big'}" @click="jumpProducts(item, index)" @mouseover="mouseoverFn(item, index)">
        <component :is="item.route?'RouterLink':'div'" :to="item.route ?? null" class="title-box"
          :class="item.route ? 'link' : ''">
            <div class="img_div" v-if="item.image">
              <img :src="item.image" width="100%" :alt="item.title ? $zhEn(item.title,item.title_en) : item.name" />
            </div>
            
            <div class="title-box flex-row" v-if="type == 'big'">
              <div class="title" v-if="item.title">{{ $zhEn(item.title,item.title_en) }}</div>
              <div class="title" v-else>{{ item.name }}</div>
              <div class="triangle"></div>
            </div>
            <div class="title-box flex-col justify-center items-center" v-if="type == 'small'">
              <div class="title center">{{ $zhEn(item.title,item.title_en) }}</div>
            </div>
        </component>
        <!-- <NavbarDropdownlist v-if="item.product && item.product.length > 0" :list="item.product"
        ></NavbarDropdownlist> -->
        
      </div>
    </div>

    <div class="level_3 flex-row" v-if="product.length != 0 && type == 'big'">
      <span v-for="(product) in product" :key="product" @click="productJump(product)">{{ product.name }}</span>
    </div>

  </div>
</template>

<script setup>
import NavbarDropdownlist from '@/common/pageComponents/NavbarDropdown/list.vue'
import { defineProps, ref, onMounted, computed } from "vue";
import { createRouter, useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
const router = useRouter();
const props = defineProps({
  lv: {
    type: Number,
    default: 3
  },
  columns: {
    type: Number,
    default: 1
  },
  type: {
    type: String,
  },
  list: {
    type: Array,
    default: function () {
      return [
        {
          title: '专项解决方案',
          childrenCols: 2,
          childrenLv: 2,
          children: [
            {
              title: '增量推广解决方案',
              route: {}
            },
            {
              title: '内容保护解决方案',
              route: {}
            },
            {
              title: '虚假登录保护方案',
              route: {}
            },
            {
              title: '用户营销优化方案',
              
              route: {}
            },
            {
              title: '商业化变现提升方案',
              
              route: {}
            },
            {
              title: '突发风险应急方案',
              
              route: {}
            },
            {
              title: '位置校验方案',
              
              route: {}
            },
            {
              title: '账户保护方案',
              
              route: {}
            },
            {
              title: '多端打通方案',
              
              route: {}
            },
          ]
        },
        {
          title: '行业解决方案',
          
          childrenCols: 2,
          childrenLv: 2,
          children: [
            {
              title: '社交行业',
              
              route: {}
            },
            {
              title: '社区行业',
              
              route: {}
            },
            {
              title: '新闻行业',
              
              route: {}
            },
            {
              title: '直播行业',
              
              route: {}
            },
            {
              title: '小说行业',
              
              route: {}
            },
            {
              title: '动漫行业',
              
              route: {}
            },
            {
              title: '航旅行业',
              
              route: {}
            },
            {
              title: '电台行业',
              
              route: {}
            },
          ]
        }
      ]
    }
  }
})
let product = ref([])

const mouseoverFn = (item, idx) => {
  if(item.product && item.product.length != 0) {
    product.value = item.product
  } else {
    return false
  }
};

const jumpProducts = (item, idx) => {
  if(props.type == 'big') {
    router.push({ path: "/products", query: { id: item.id } });
  } else if(props.type == 'small') {
    router.push({ path: "/newslist", query: { id: item.id } });
  }
};

const productJump = (item) => {
  router.push({ path: '/detail1', query: { id: item.id }, hash: "#tabs" })
}
let gridTemplateColumns = computed(()=> {
  return props.list.slice(0, props.columns).map(i => {
    return (i.weight ?? 1) + 'fr'
  }).join(' ')
})

</script>
  
<style scoped lang="less">
.list-container {
  // display: grid;
  // column-gap: 20px;
  width: 100%;

  .item{
    width: 240px;
    height: 100%;
    margin-right: 80px;
    .img_div{
      width: 100%;
      height: 120px;
      overflow: hidden;
      cursor: pointer;

      img {
        transition: transform 0.3s ease;
      }
    }
    .title-box{
      margin-top: 12px;
      cursor: pointer;

      
      .triangle {
        margin: auto 0;
        margin-left: 17px;
        width: 7px;
        height: 11px;
        background: #ffffff;
        clip-path: polygon(0 0, 0 100%, 100% 50%);
      }
    }
    &:hover {
      .title-box .title{
        color: #0083ff;
      }
      .img_div img{
        transform: scale(1.2)
      }
      .title-box .triangle{
        transform: rotate(90deg);
        background: #0083ff;
      }
    }
   
  }

  .level_3{
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 20px;
    margin-top: 40px;
    span{
      color: rgba(255, 255, 255, 0.8);
      font-size: 18px;
      margin-right: 120px;
      cursor: pointer;
      &:hover{
        color: #0083ff;
      }
    }
  }

  .title {
    font-weight: 500;
    color: #ffffff;
    font-size: 16px;
    &.center{
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
      &:hover{
        color: #0083ff;
      }
    }
  }


  
}</style>
  