import { useUserStore } from "@/stores/user";

export function flexible(window, document) {
  var docEl = document.documentElement;
  // document.body.style.fontSize = "12px";

  // set 1rem
  function setRemUnit() {
    docEl.style.fontSize = getRem() + "px";
  }

  setRemUnit();

  // reset rem unit on page resize
  window.addEventListener("resize", setRemUnit);
  window.addEventListener("pageshow", function (e) {
    if (e.persisted) {
      setRemUnit();
    }
  });
}

export function getRem() {
  if (window.innerWidth > 1280) {
    useUserStore().setIsMobile(false)
    return 12
  } else if (window.innerWidth > 768) {
    useUserStore().setIsMobile(false)
    return (window.innerWidth / 1280) * 12;
  } else {
    useUserStore().setIsMobile(true)
    // 0.75手机端缩放比例
    return (window.innerWidth / 375) * 12 * 0.75;
  }
}
