<template>
  <div
    class="pro_container"
    ref="pro"
  >
    <div class="desc" :class="useUserStore().isMobile ? '' : 'hoverClass'">
      {{ useUserStore().language == "zh-CN" ? props.desc : props.desc_en }}
    </div>
    <div class="title" :class="useUserStore().isMobile ? '' : 'hoverClass'">{{ props.title }}</div>
    <img :src="props.img" width="100%" :alt="props.title" />
    <div class="btn flex-row items-center justify-center" :class="useUserStore().isMobile ? '' : 'hoverClass'">
      <span>{{ $t("learnMore") }}</span>
      <img
        src="@/assets/images/arrow-right.png"
        width="12"
        height="12"
        :alt="$t('learnMore')"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
import defBg from "@/assets/images/product_def_bg.png";

import { useUserStore } from "@/stores/user";

let pro = ref("pro");

const props = defineProps({
  desc: {
    type: String,
  },
  desc_en: {
    type: String,
  },
  title: {
    type: String,
  },
  img: {
    type: String,
  },
  bgImg: {
    type: String,
  },
  id: {
    type: Number,
  },
});

// const enterFn = () => {
//   if (!useUserStore().isMobile) {
//     pro.value.style.background = `url(${props.bgImg}) 50% 50% / cover`;
//   }
  
// };

// const overFn = () => {
//   if (!useUserStore().isMobile) {
//     pro.value.style.background = `url(${defBg}) 50% 50% / cover`;
//   }

// };
</script>

<style lang="less" scoped>
.pro_container {
  width: 270px;
  height: 370px;
  padding: 40px;
  background: url("@/assets/images/product_def_bg.png") 50% 50% / cover;
  cursor: pointer;
  overflow: hidden;
  img{
    transition: transform 0.3s ease;
  }
  &:hover {
    img{
      transform: scale(1.2);
    }
  }

  @media (max-width: 769px) {
    width: 210px;
    height: 320px;
  }

  

  &:hover .hoverClass {
    display: flex;
    // align-items: center;
  }

  .title {
    margin-top: 12px;
    margin-bottom: 45px;
    height: 46px;
    font-size: 30px;
    font-weight: 500;
    color: #333333;
    line-height: 46px;
  }

  .desc {
    height: 36px;
    border-bottom: 1px solid #ffffff;
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }

  .btn {
    margin-top: 20px;
    padding: 0 15px;
    height: 28px;
    border-radius: 4px;
    border: 1px solid #0083FF;
    width: fit-content;
    display: none;

    span {
      font-size: 14px;
      color: #0083FF;
    }
  }
}
</style>
