<template>
  <div class="navbar-dropdown-container flex-col items-center justify-center" v-if="list.length > 0">
    <div class="content-box flex-row" :class="[`${type}`]" :style="{ width: widthPx ? $pxToRem(widthPx) : undefined }">
      <NavbarDropdownlist :list="list" :columns="columns" :lv="lv" :type="type"></NavbarDropdownlist>
    </div>
  </div>
</template>

<script setup>

import { defineProps, ref, onMounted, computed } from "vue";
import NavbarDropdownlist from '@/common/pageComponents/NavbarDropdown/list.vue'
const props = defineProps({
  type: {
    type: String,
  },
  widthPx: {
    type: Number,
    default: undefined
  },
  lv: {
    type: Number,
    default: 3
  },
  columns: {
    type: Number,
    default: 1
  },
  list: {
    type: Array,
    default: function () {
      return []
    }
  }
})

onMounted(()=> {
})

</script>

<style scoped lang="less">
.navbar-dropdown-container {
  z-index: 200;


  .content-box {
    width: @--effect-width;
    background: #333333;
    box-shadow: @--box-shadow;
    border-radius: 10px;
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    &.big{
      padding-left: 60px;
      padding-right: 60px;
      padding-top: 40px;
      padding-bottom: 40px;
    }

    
  }
}
</style>
