import { createI18n } from "vue-i18n";
import en from './language/en.json'
import zh from './language/zh.json'
import enUi from 'view-ui-plus/dist/locale/en-US'
import zhUi from 'view-ui-plus/dist/locale/zh-CN'

const messages = {
    en,
    zh,
    'en-US': enUi,
    'zh-CN': zhUi,
};

const i18n = createI18n({
    locale: localStorage.getItem('language') || 'zh-CN', // 默认语言
    fallbackLocale: 'en', // 如果当前语言不存在使用默认语言
    messages,
    allowComposition: true,
    silentFallbackWarn: true,
    missingWarn: false,
    silentTranslationWarn: true,
    fallbackWarn: false,
});
  
export default i18n;