import { defineStore } from 'pinia'
import { headerNavs } from "@/api/home"
import { reqConfig } from "@/api/contact";
export const useUserStore = defineStore('app', {
    state: () => ({
      metaInfo: {
        title: "",
        keywords: "",
        description: ""
      },
      isMobile: false,
      language: localStorage.getItem('language') || 'zh-CN',
      sideNavConfig: null,
      menusList:[],
      sysConfig: null
    }),
    actions: {
      setIsMobile(value) {
        this.isMobile = value
      },
      setSlideConfig(value) {
        this.sideNavConfig = value
      },
      setMenusList(value) {
        this.menusList = value
      },
      setLanguage(val){
        this.language = val
        localStorage.setItem('language', val);
      },
      setMetaInfo(metaInfo) {
        this.metaInfo = metaInfo;
      },

      async getMenuList() {
        const response = await headerNavs()
        if (response.code === 200001) {
          response.data.map(item => {
            item.drapdown = null
            if(item.id == 2) {
              item.drapdown = {
                type: 'big',
                columns: 2,
                lv: 2,
                list: item.data
              }
            } else if(item.id == 7) {
              item.drapdown = {
                widthPx: 140,
                type: 'small',
                columns: 1,
                lv: 2,
                list: item.data
              }
            }
            
          })
          this.menusList = response.data
          console.log(this.menusList,111)
          let menus = response.data;
          localStorage.setItem("menus", JSON.stringify(menus));
          
        }
      },

      async getSysConfig() {
        let res = await reqConfig();
        if (res.code === 200001) {
          // let headerCode = window.ssrHeaderCode(res.data.site_inclusion_config)
          // window.seometa(headerCode)
          let { official_account_image, map_location_url } = res.data
          this.setSlideConfig({
            mapUrl: map_location_url,
            qrCode: official_account_image
          })
          this.sysConfig = res.data
          // topLogo.value = res.data.log_top_image
          localStorage.setItem('topLogo', res.data.log_top_image)
          localStorage.setItem('brandImage', res.data.brand_image)
        
        }
      }
    },
  });