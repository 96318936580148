<template>
  <div class="sidenav_container" v-if="!hideSide">
    <div
      class="side_item flex-row justify-center"
      v-for="(item, idx) in sideItem"
      :key="idx"
    >
      <img :src="item.url" :alt="item.url" @click="clickFn(idx)" />
    </div>
  </div>

  <el-dialog
    v-model="modal"
    style="border-radius: 6px"
    custom-class="dialog"
    :width="isMobile ? '80%' : '30%'"
    center
    :lock-scroll="false"
    :show-close="false"
  >
    <div class="modal_box">
      <div class="modal_title">扫描二维码</div>
      <div class="modal_text">关注微信公众号获取最新信息！</div>
      <img :src="props.qrCode" :alt="props.qrCode" />
    </div>
  </el-dialog>
</template>

<script setup>
import icon1 from "@/assets/images/side_icon1.png";
import icon2 from "@/assets/images/side_icon2.png";
import icon3 from "@/assets/images/side_icon3.png";
import icon4 from "@/assets/images/side_icon4.png";

import { ElDialog } from "element-plus";
import { watch } from "vue";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

import { useUserStore } from "@/stores/user";
import { computed } from "vue";
import router from "@/router";

const store = useUserStore();
let isMobile = computed(() => store.isMobile);

let modal = ref(false);
let hideSide = ref(true);
const route = useRoute();

const props = defineProps({
  jumpUrl: {
    type: String,
  },
  qrCode: {
    type: String,
  },
});

let sideItem = [{ url: icon1 }, { url: icon2 }, { url: icon3 }, { url: icon4 }];

const clickFn = (idx) => {
  switch (idx) {
    case 0:
      router.push('/contactus#jobAddress')
      break;
    case 1:
      window.open(props.jumpUrl)
      break;
    case 2:
      modal.value = true;
      break;
    case 3:
      window.scrollTo({ top: 0, behavior: "smooth" });
      break;
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

watch(
  () => route.path,
  (n) => {
    handleScroll();
  }
);

const handleScroll = (e) => {
  if (window.scrollY > 300) {
    hideSide.value = false;
  } else if (route.path != "/") {
    hideSide.value = false;
  } else {
    hideSide.value = true;
  }
};
</script>

<style lang="less" scoped>
.sidenav_container {
  position: fixed;
  right: 10px;
  top: 40%;
  width: 70px;
  height: 280px;
  background: #0083ff;
  border-radius: 4px;
  padding: 10px 20px;
  z-index: 10;

  .side_item {
    height: 65px;

    img {
      width: 30px;
      height: 30px;
      margin: auto;
      cursor: pointer;
    }
  }

  .side_item:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
}

.modal_box {
  width: 100%;
  height: 400px;
  margin: 0 auto;
  padding: 52px 44px 82px 44px;
  text-align: center;

  .modal_title {
    height: 20px;
    font-size: 24px;
    font-weight: 500;
    color: #121619;
    line-height: 20px;
  }

  .modal_text {
    margin-top: 20px;
    margin-bottom: 40px;
    height: 20px;
    font-size: 18px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
  }

  img {
    width: 150px;
    height: 150px;
  }
}

.modal_div {
  width: 100% !important;
}
</style>
