<template>
  <div
    class="table-container"
    ref="table"
    
  >
    <!-- <img class="bgImg" width="100%" height="100%" :src="data[nowIdx].background" alt=""> -->
    
      
        <!-- 内容放在这里 -->
        <div class="page_title" >
          {{ $t('applyTerminal') }}
        </div>

        <div class="main flex-row flex-wrap justify-between">
          <div
            class="item"
            v-for="(item, index) in data"
            :key="index"
            @mouseenter="enterFn(0)"
            @click="jumpFn(item)"
            
          >
            <img class="img" :src="item.background" :alt="useUserStore().language == 'zh-CN' ? item.title : item.title_en" />
            <div class="content">
              <div class="title">{{ useUserStore().language == 'zh-CN' ? item.title : item.title_en }}</div>
              <div class="desc flex-row justify-between">
                <span>{{ useUserStore().language == 'zh-CN' ? item.desc : item.desc_en }}</span>
                <span class="more">{{ $t("learnMore") }}</span>
              </div>
            </div>
            
          </div>
        </div>
        <div class="more_btn flex-row items-center" v-if="rowIndex === 3 && colIndex === 2" @click="$router.push('/solution')">
          <span>{{  $t("learnMore")  }}</span>
          <img
            src="@/assets/images/arrow right@2x.png"
            width="16"
            height="16"
            :alt="$t('learnMore')"
          />
        </div>
      
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import {
  homeApplay,
} from "@/api/home";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
const router = useRouter()
let table = ref("table");
const rows = [
  { height: 200 },
  { height: 360 },
  { height: 360 },
  { height: 200 },
];

const cols = [
  { width: (window.innerWidth - 1200) / 2 },
  { width: 400 },
  { width: 400 },
  { width: 400 },
  { width: (window.innerWidth - 1200) / 2 },
];

let nowIdx = ref(0)

let data = ref([])

const jumpFn = (item,idx) => {
  window.location.href = item.jumpUrl
  // router.push({
  //   path: '/solutiondetail',
  //   query:{
  //     id: item.id,
  //     arr: JSON.stringify(data.value)
  //   }
  // })
}

const loadImage = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve(url);
  });
};

const enterFn = async (idx) => {
  nowIdx.value = idx
  // const imageUrl = data.value[idx].background;
  // console.log(imageUrl);
  // try {
  //   // 预加载图片
  //   await loadImage(imageUrl);

  //   // 设置背景
  //   table.value.style.background = `url(${imageUrl}) 50% 50% / cover`;
  // } catch (error) {
  //   console.error("图片加载失败", error);
  // }
};

const getData = async () => {
    let res = await homeApplay()
    if (res.code === 200001) {
      data.value = res.data.map(item=>{
        return{
          title: item.title,
          title_en: item.title_en,
          desc: item.describe,
          desc_en: item.describe_en,
          id: item.id,
          background: item.background_image,
          jumpUrl: item.jump_url
        }
      })
    }
}


onMounted(()=>{
  getData()
})
</script>

<style scoped lang="less">


.main {
  width: @--effect-width;
  height: 100%;
  padding: 100px 0px;
  margin: 0 auto;
}


.page_title {
  height: 50px;
  margin: auto 0;
  font-size: 36px;
  text-align: center;
  font-weight: 600;
  color: #222222;
  line-height: 50px;
}

.more_btn {
  width: 120px;
  height: 40px;
  background: linear-gradient(90deg, #0083ff 0%, #0ec7d9 100%);
  border-radius: 4px;
  padding: 10px 24px;
  cursor: pointer;

  &:hover{
    img{
      transform: translateX(8px);
    }

    background: linear-gradient(90deg, #0066FF 0%, #00B2FF 100%);
  }

  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #ffffff;
  }
  img {
    transition: transform 0.5s ease;
  }
}

.item {
  width: 373px;
  height: 268px;
  background: linear-gradient( 180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  margin-bottom: 40px;
  overflow: hidden;
  &:hover {
    background: rgba(0, 131, 255, 0.8);
    .img{
      transform: scale(1.2);
    }
    .more{
      color: #0083ff;
    }
  }
  &:hover .item_btn {
    span {
      display: block;
    }
  }
  .img{
    transition: transform 0.3s ease;
    position: absolute;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 8;
  }
  .content{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
  }
  .title {
    font-size: 22px;
    font-weight: 500;
    color: #ffffff;
    padding-top: 198px;
    padding-left: 30px;
  }

  .desc {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    span{
      &:first-child{
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        width: 75%;
      }
    }
  }

  .item_btn {
    width: 126px;
    cursor: pointer;

    span {
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      margin-right: 10px;
      display: none;
    }

    img {
      vertical-align: top;
    }
  }
}

@media (max-width: 769px) {
  .item{
    width: 100%;
  }
}
</style>
