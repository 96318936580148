<template>
  <div class="aboutus_container">
    <div class="main">
      <div class="page_title">{{ useUserStore().language == 'zh-CN' ? props.pageTitle : props.pageTitleEn }}</div>
      <div class="sub_title">{{ useUserStore().language == 'zh-CN' ? props.subPageTitle : props.subPageTitle }}</div>
      <div class="text" v-html="useUserStore().language == 'zh-CN' ? props.html : props.htmlEn"></div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useUserStore } from '@/stores/user';

const props = defineProps({
  html: {
    type: String
  },
  htmlEn: {
    type: String
  },
  pageTitle: {
    type: String
  },
  pageTitleEn: {
    type: String
  },
  subPageTitle: {
    type: String
  },
  subPageTitleEn: {
    type: String
  }
})

// let html =
//   ' <p style="text-indent:2em;">深圳超盈智能科技有限公司2011年成立至今专注于半导体储存器领域， 主营嵌入式存储芯片及内存产品； 公司拥有经验丰富的自主研发团队， 具备先进半导体储存器自主研发及封测制造能力，创建自主品牌KEYMOS，已与全球知名的Memory厂商建立深度的战略合作伙伴关系。近年在稳健创收的基础上，蓄力在创新、科技方向探索转型。公司已荣获“市级专精特新”、“国家高新技术企业”、“创新型中小企业”称号。</p><br/><p style="text-indent:2em;">公司秉承以“芯”为本，深耕实做，以深圳为研发生产贸易中心，联动香港贸易中心，以全球化视野布局国际市场，“超凡存储、盈领未来”超盈将蓄力前行致力成为 “国际知名存储方案解决商”。</p>';

</script>

<style lang="less" scoped>
.aboutus_container {
  width: 100%;
  // min-height: 490px;
  padding: 100px 0px;
  background: url("@/assets/images/aboutus_bg.png") 50% 50% / cover;

  .main {
    width: @--effect-width;
    height: 100%;
    margin: 0 auto;

    .page_title {
      height: 50px;
      font-size: 36px;
      text-align: center;
      font-weight: 600;
      color: #222222;
      line-height: 50px;
    }
    .sub_title{
      text-align: center;
      color: #0083FF;
      font-size: 24px;
      font-weight: 600;
      padding-top: 5px;
    }

    .text {
      margin-top: 60px;
      font-size: 16px !important;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
    }
  }
}
</style>
